import './App.css';

import scgLogo from './img/scg-logo.svg';
import wearescg from './img/wearescg.svg';
import nsn from './img/nsn.svg';
import circles from './img/2-circles.svg';
import hello from './img/hello.png';
import myPhones from './img/myphones.svg';
import arrowRight from './img/arrow-right-solid.svg';

function App() {
  return (
    <div className='App'>
      <div className='h-screen flex flex-col justify-between'>
        <header className='flex p-6 lg:p-12'>
          <img src={scgLogo} alt='SCG' />
        </header>
        <main className='flex flex-col items-center px-6 lg:px-12'>
          <img className='mt-12 lg:mt-0 mb-20 w-3/4 lg:w-96' src={wearescg} alt='wearescg' />
          <div className='grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8 mb-20'>
            <a href='https://www.nsn.co/' target='_blank' rel='noreferrer' className='bg-pale cursor-pointer py-6 px-10 flex rounded ease-out duration-200 hover:scale-105 hover:shadow-lg hover:shadow-navy/20'>
              <img className='w-32' src={nsn} alt='NSN' />
            </a>
            <a href='https://www.2circles.com/' target='_blank' rel='noreferrer' className='bg-pale cursor-pointer py-6 px-10 flex rounded ease-out duration-200 hover:scale-105 hover:shadow-lg hover:shadow-navy/20'>
              <img className='w-32' src={circles} alt='2 Circles' />
            </a>
            <a href='https://www.hello-telecom.co.uk/' target='_blank' rel='noreferrer' className='bg-pale cursor-pointer py-6 px-10 flex rounded ease-out duration-200 hover:scale-105 hover:shadow-lg hover:shadow-navy/20'>
              <img className='w-32' src={hello} alt='hello' />
            </a>
            <a href='https://www.myphones.com/' target='_blank' rel='noreferrer' className='bg-pale cursor-pointer py-6 px-10 flex rounded ease-out duration-200 hover:scale-105 hover:shadow-lg hover:shadow-navy/20'>
              <img className='w-32' src={myPhones} alt='My Phones' />
            </a>
          </div>
          <a className='relative bg-navy leading-10 pl-4 pr-14 ease-out duration-200 hover:pr-16 h-10 rounded-full text-white' href="mailto:chris.t@courtsdesign.com,ross.w@courtsdesign.com"><strong>Learn More</strong><span className='bg-white bg-opacity-10 absolute right-0 w-10 h-10 rounded-full text-center'><img className='w-4 h-10 m-auto' src={arrowRight} alt='arrow' /></span></a>
        </main>
        <footer className='px-6 py-12 lg:px-12'>
          <p className='text-center text-sm'>Copyright {(new Date().getFullYear())} Southern Communications Limited  |  Registered in England, Number 01328040</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
